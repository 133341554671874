import React from 'react';
import BlogBanner from '../../components/blog/BlogBanner';
import blogThumbnail from '../../assets/images/blog/blog2/thumbnail.png';
import blogInnerImage1 from '../../assets/images/blog/blog2/img-1.png';
import BlockQuote from '../../components/blog/BlockQuote';
import ContentSectionWithImg from '../../components/blog/ContentSectionWithImg';
import ContentSection from '../../components/blog/ContentSection';
import KeyConcepts from '../../components/blog/KeyConcepts';

const Blog2 = () => {
  return (
    <div>
      <BlogBanner
        heading={`Unboxing the AI Black Box: A Journey into the
        Heart of Explainable AI`}
        paragraph1={`In a world dazzled by the wizardry of Artificial Intelligence (AI), Explainable AI or XAI is the affable professor who takes you aside and whispers, “Let me show you how the magic trick works.” It’s the antidote to the often-perplexing AI show where, without XAI, the audience is left applauding a performance they scarcely understand. `}
        thumbnail={blogThumbnail}
        paragraph2={`In today’s world where artificial intelligence (AI) permeatesevery corner of our lives, the term "black box" has become as common in tech circles as espressos in a coffee shop. The reference is to complex AI systems whose inner workings are opaque, even to their creators. As these systems decide everything from who gets a job to who gets a loan or what treatment a patient should receive, the necessity for understanding the "why" and "how" behind their decisions has birthed a critical discipline: Explainable Artificial Intelligence (XAI).`}
        authorCardContent={`07 March, 2024 | 5 min read`}
      />
      <div className='brown-bg pt-1'>
        <div className='container'>
          <ContentSection
            isDiamondPresent={false}
            heading={`The Enigma of AI Decisions: Why XAI Matters`}
            paragraph={`Imagine you’ve been turned down for a loan by a smug, silent robot or you’ve been diagnosed with a rare disease by a computer that refuses to elaborate. It’s like being dumped by text with no explanation—frustrating and cold. Enter XAI, a movement to make these complex algorithms as easy to read as a children’s book—albeit a technically complex one with lots of charts. At its core, XAI is about peeling back the layers of complex AI models to reveal the mechanics of their decisions. It's akin to a master chef revealing the secret ingredients of their signature dish. In the professional world, where accountability and comprehension are paramount, XAI serves a banquet of clarity and confidence in AI-driven decisions.`}
          />
          <ContentSection
            isDiamondPresent={false}
            heading={`The ABCs of XAI`}
            paragraph={`First things first, let’s unravel what XAI really is. In tech speak, XAI refers to methods and techniques in artificial intelligence that make the results of AI models transparent and understandable to humans. It's like having subtitles in a foreign movie.`}
          />

          <KeyConcepts
            title={`Key Concepts`}
            split2One={`<b>LIME (Local Interpretable Model-agnostic Explanations):</b> Your
            personal AI detective, breaking down complex AI decisions on a
            case-by-case basis. [1] Local Interpretable Model-Agnostic
            Explanations (LIME) is like the quirky detective who can explain why
            the quiet neighbour (i.e., a particular data point) is the key suspect
            in a lineup of potential loan defaulters. It picks out the most
            influential features that led to a prediction, leaving the rest in the
            shadows.`}
            split2Two={` <b>SHAP (SHapley Additive exPlanations): </b>
            The fair-play referee, ensuring every feature in your AI model gets
            credit for its contribution. [2] SHapley Additive exPlanations (SHAP),
            on the other hand, draws from cooperative game theory, attributing
            each feature a "Shapley value." SHAP values assign a fair "game score"
            to each feature in the decision-making process. If AI were a soccer
            team, SHAP would be the statistician proving that while the striker
            gets the glory, it’s really the midfielders (subtle data features)
            setting up the goals (predictions). SHAP does this for features in a
            model, clarifying each one's role in the final decision.`}
          />

          <ContentSection
            heading={`Case Studies: XAI in the Wild`}
            isDiamondPresent={false}
          />

          <BlockQuote
            title={`Healthcare: The Diagnostic Narrators`}
            desc={`In healthcare, the stakes are life and high. An XAI application in diagnosing diseases can be the
            difference between life and death. But when AI prescribes a rare medication, both doctor and
            patient might think, “Uh, come again?” That's where XAI steps in, shining a light on the AI’s
            “thought” process. For example, take the AI systems used for diagnosing diseases like diabetic
            retinopathy. XAI helps to peel back the layers of these complex decisions, ensuring medical
            professionals aren't left scratching their heads. [3]
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>
            Feature Attribution - Identifying which features (like a particular spot in a retinal image) led to the
            AI’s conclusion.`}
          />

          <BlockQuote
            title={`Finance: Transparent Credit Scoring`}
            desc={`Finance and AI are like peanut butter and jelly - perfect together but sometimes messy. an AI
            model that decides creditworthiness can make or break people's dreams. AI decides who gets a
            loan and who needs to save more. But why did you get rejected for that loan? XAI to the rescue! It
            can explain that maybe those late-night online shopping sprees weren’t such a good idea.
            Financial institutions are increasingly adopting XAI to make their loan approval (or rejection)
            process more transparent, helping customers understand and improve their financial profiles. [4]
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>
            Model Transparency - Like a chef showing you the recipe, it's about making AI’s decision-making
            process clear`}
          />
          <BlockQuote
            title={`Autonomous Vehicles: Why Did My Car Just Do That?`}
            desc={`In the automotive sector, consider the self-driving car: a marvel of AI. But what happens when it
            makes an unexpected swerve or suddenly brakes? XAI can dissect the decision, perhaps
            revealing that the AI detected a hard-to-see road hazard. By understanding these choices,
            manufacturers can refine AI behaviours and regulators can develop better safety standards.
            
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>Causal Inference - Deciphering the reasons behind AI’s actions.`}
          />
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <BlockQuote
                title={`Retail: Why Am I Being Recommended This?`}
                desc={`Ever got a product recommendation online and wondered, “Why on earth?” That's AI studying your purchase behaviour. XAI lets you peek behind the curtain, possibly revealing that your oneoff
            search for "funny hats" has led to some interesting suggestions.
            
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>User Interpretability - Making the AI’s language shopper-friendly.`}
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <img
                src={blogInnerImage1}
                alt=''
                className='img img-responsive'
              />
            </div>
          </div>

          <BlockQuote
            title={`Manufacturing: AI, the Efficient Factory Hand`}
            desc={`In manufacturing, AI optimizes everything from production lines to supply chains. But how does it
            decide to change a production process? XAI can explain that it's not just throwing darts at a
            board; it's making calculated decisions based on data trends.
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>Process Transparency - Unveiling the 'how' and 'why' behind AI’s operational decisions.`}
          />
          <BlockQuote
            title={`Customer Service: AI, the Friendly Helper`}
            desc={`Customer service chatbots are often powered by AI. But why did the bot suggest this particular
            solution to your problem? XAI can reveal that it's not just magic; it's analyzing thousands of
            similar queries to find the best answer.
            
            <br/>
            <br/>
            <b>Techie Talk</b>
            <br/>Decision Logic Explanation - Understanding the rationale behind AI’s suggestions.`}
          />

          <ContentSection
            extraClassName={'pb-5'}
            heading={`Wrapping Up: The XAI Chronicles`}
            paragraph={`As we sail into the AI-infused future, XAI stands as a lighthouse, guiding us through the foggy
            waters of complex algorithms. The hope is that with XAI, AI decisions will become as clear as day.
            In the grand narrative of AI, XAI is our trusty guide, turning AI’s cryptic monologues into friendly
            banter. So next time an AI system makes a decision that affects you, remember, there’s an entire
            field dedicated to making sense of it.`}
            list={true}
            listHeading={`References:`}
            listItems={[
              `Ribeiro, M. T., Singh, S., & Guestrin, C. (2016). "Why Should I Trust You?" Explaining the
              Predictions of Any Classifier. Proceedings of the 22nd ACM SIGKDD International
              Conference on Knowledge Discovery and Data Mining.
              `,
              `Lundberg, S. M., & Lee, S.-I. (2017). A Unified Approach to Interpreting Model Predictions.
              Advances in Neural Information Processing Systems.`,
              `Sayres, R. et al. (2019). Using a Deep Learning Algorithm and Integrated Gradients
              Explanation to Assist Grading for Diabetic Retinopathy. Ophthalmology.
              `,
              `Arrieta, A. B., et al. (2020). Explainable Artificial Intelligence (XAI): Concepts, taxonomies,
              opportunities and challenges toward responsible AI. Information Fusion.`,
            ]}
          />

          {/* 
          <ContentSectionWithImg
            heading={`The Tale of Targeted Marketing`}
            paragraph={`Ever used a map app to get the fastest route? That’s Data
          Analytics checking traffic patterns and AI adjusting your route in
          real-time. If you ever avoided a two-hour traffic jam, thank your
          lucky stars for this tech tag-team. Traffic congestion is a daily
          challenge, especially in the metropolitan areas. By leveraging AI,
          traffic signals adapt in real-time to traffic conditions, gathered
          through a network of sensors and cameras. This information is
          crunched using advanced data analytics to predict flow patterns
          and adjust signals accordingly to minimise congestion.
          <br />
          <br />
          E-commerce platforms employ AI-driven recommendation engines to
          analyse the vast amounts of data they gather about consumer
          behaviour. Through machine learning algorithms, they can predict
          what products a customer is likely to be interested in, leading to
          a highly personalised shopping experience.`}
            img={blogDetail1inner1}
          />
          <ContentSectionWithImg
            heading={`The Fitness Coaches You Never Knew You Needed`}
            paragraph={`Healthcare has also seen a tremendousimpact with the introduction of AI anddata analytics in everyday devices suchas smartwatches and fitness trackers.They're mini-labs on your wrist, poweredby—you guessed it—Data Analytics andAI. They monitor your vitals like heartrate, sleep patterns, physical activity, andcheer you on to take those 10,000 steps,and even nudge you to get more sleep.
            <br />
            <br />
          These devices collect data on AI algorithms that can detect anomalies that may indicate health issues, often before Captionthe user notices any symptoms, and suggest early interventions.`}
            img={blogDetail1inner2}
            isContentRight={true}
          />

          <BlockQuote
            title={`The Secret Recipe Behind Your Streaming Binges`}
            desc={`Ever wondered how your streaming service knows you'd love that quirky rom-com or that spine-chilling thriller? That’s our power couple at play again. They analyse your viewing habits and suggest shows that keep you glued to your couch, popcorn in hand.`}
          />
          <BlockQuote
            title={`Smarter Homes for Smarter Living`}
            desc={`Smart homes used to be the stuff of sci-fi, but now they're just... homes. Thermostats learn your comfort levels, lights know when you're home, and fridges can even remind you to buy milk. It's like having a butler who's also a genius.`}
          />
          <BlockQuote
            title={`Synergy in Practice`}
            desc={`The synergy between data analytics and artificial intelligence is more than just their combined use; it is about the harmonious interaction that amplifies their individual capabilities. Data Analytics provides the groundwork by gathering and making sense of data and Artificial Intelligence uses this data to learn, predict, and act without human intervention.`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Blog2;
