import React from 'react';
import { GiDiamonds } from '../config/icons';
import { blogImg1, blogImg2, blogImg3 } from '../assets/images';
import OwlCarousel from 'react-owl-carousel';
import MarqueRunning from './MarqueRunning';
import { blogLinks } from '../screens/Blog';
import blogThumbnail1 from '../assets/images/blog/blog1/thumbnail.png';
import blogThumbnail2 from '../assets/images/blog/blog2/thumbnail.png';
import blogThumbnail3 from '../assets/images/blog/blog3/thumbnail.png';
import blogThumbnail4 from '../assets/images/blog/blog4/thumbnail.png';
import blogThumbnail5 from '../assets/images/blog/blog5/thumbnail.png';
import blogThumbnail6 from '../assets/images/blog/blog6/thumbnail.png';
import blogThumbnail7 from '../assets/images/blog/blog7/thumbnail.png';
import blogThumbnail8 from '../assets/images/blog/blog8/thumbnail.png';
import blogThumbnail9 from '../assets/images/blog/blog9/thumbnail.png';
import blogThumbnail10 from '../assets/images/blog/blog10/thumbnail.png';
import blogThumbnail11 from '../assets/images/blog/blog11/thumbnail.png';
const BlogSec = ({ bg, title = 'From My Blog', showSeeAll = true }) => {
  const blogData = [
    {
      img: blogThumbnail1,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `The Symbiotic Relationship<br/> of the 21st Century`,
      parra: `Once upon a time, in the not-so-distant past, Data Analytics and Artificial Intelligence (AI)...`,
      link: blogLinks[0].path,
    },
    {
      img: blogThumbnail2,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Unboxing the AI Black Box: A Journey into the <br/>
      Heart of Explainable AI`,
      parra: `In a world dazzled by the wizardry of Artificial Intelligence (AI)...`,
      link: blogLinks[1].path,
    },

    {
      img: blogThumbnail3,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Federated Learning: A Data Odyssey`,
      parra: `In an era where data is as valuable as currency, the concept of federated learning emerges as a
      revolutionary approach in the realm of machine learning...`,
      link: blogLinks[2].path,
    },
    {
      img: blogThumbnail4,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `The Matrix World of Neural Networks`,
      parra: `Neural networks, often referred to as artificial neural networks (ANNs), have revolutionised the
      field of machine learning and artificial intelligence (AI)...`,
      link: blogLinks[3].path,
    },
    {
      img: blogThumbnail5,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Understanding GANs - Generative Adversarial Networks`,
      parra: `Generative Adversarial Networks, or GANs, represent a revolutionary breakthrough in the field of
      artificial intelligence and machine learning...`,
      link: blogLinks[4].path,
    },
    {
      img: blogThumbnail6,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `When Quantum Computing met AI`,
      parra: `In the fascinating universe of technology, two stars have been gaining luminosity: Quantum
      Computing and Artificial Intelligence (AI)...`,
      link: blogLinks[5].path,
    },
    {
      img: blogThumbnail7,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Digital Twin: It’s Like Looking In A Mirror`,
      parra: `As the name suggests, a digital twin is a digital representation of a physical object, system, or
      process. This concept integrates various...`,
      link: blogLinks[6].path,
    },
    {
      img: blogThumbnail8,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Neuro-Symbolic AI: The Convergence of Neural Networks and Symbolic Reasoning`,
      parra: `The field of artificial intelligence (AI) has witnessed a significant evolution, driven by the
      emergence of neuro-symbolic AI, a paradigm that merges...`,
      link: blogLinks[7].path,
    },
    {
      img: blogThumbnail9,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Market Mix Modelling: The Subtle Art of Decoding Marketing Dynamics`,
      parra: `Market Mix Modelling, often abbreviated as MMM, is an analytical technique used in marketing to understand how various marketing activities contribute to sales and other key performance...`,
      link: blogLinks[8].path,
    },
    {
      img: blogThumbnail10,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `The Linguistic Alchemy of Natural Language Processing`,
      parra: `In a world where machines converse, jokes are decoded by algorithms, and books are summarised
      in the blink of an eye, lies the enchanting realm of Natural Language Processing (NLP)...`,
      link: blogLinks[9].path,
    },
    {
      img: blogThumbnail11,
      featureone: `SEO Mastery`,
      featuretwo: `Content Writing`,
      header: `Collaborative AI: Harnessing the Power of Collective Intelligence`,
      parra: `The advent of AI has traditionally been viewed through a lens of automation and the replacement
      of human tasks. What was once considered to be achieved...`,
      link: blogLinks[10].path,
    },
  ];
  return (
    <>
      <div className={`total_blogsec ${bg}`}>
        {showSeeAll && (
          <button className='see_allbtn btn'>
            See All <GiDiamonds />
          </button>
        )}

        <div className='head_star_sec head_star_sectwo' data-aos='fade-right'>
          <span>
            <GiDiamonds />
          </span>{' '}
          <h1>{title}</h1>{' '}
          <span>
            <GiDiamonds />
          </span>
        </div>
        <div className='container'>
          <div className='total_carouselsec' data-aos='fade-down'>
            <OwlCarousel
              className='owl-theme blog_carousel'
              autoplay={true}
              loop={true}
              margin={20}
              nav={true}
              dots={false}
              responsive={{
                0: {
                  items: 1,
                  animateOut: 'slideOutDown',
                  animateIn: 'flipInX',
                  nav: true,
                  mouseDrag: false,
                  mergeFit: true,
                  autoplayHoverPause: true,
                },
                600: {
                  items: 2,
                  animateOut: 'slideOutDown',
                  animateIn: 'flipInX',
                  nav: true,
                  mouseDrag: false,
                  mergeFit: true,
                  autoplayHoverPause: true,
                },
                1000: {
                  items: 4,
                  animateOut: 'slideOutDown',
                  animateIn: 'flipInX',
                  nav: true,
                  mouseDrag: false,
                  mergeFit: true,
                  autoplayHoverPause: true,
                  navText: [
                    "<div class='nav-button owl-prev'><i class='fa-solid fa-arrow-left'></i></div>",
                    "<div class='nav-button owl-next'><i class='fa-solid fa-arrow-right'></i></div>",
                  ],
                },
              }}>
              {blogData?.map((data, index) => {
                return (
                  <div key={index} className='blogs_card'>
                    <img src={data?.img} alt='Blog' />
                    <div className='feature_sec'>
                      <h6 className='feature'>{data?.featureone}</h6>
                      <span>
                        <GiDiamonds />
                      </span>
                      <h6 className='feature'>{data?.featuretwo}</h6>
                    </div>
                    <h5
                      dangerouslySetInnerHTML={{ __html: data?.header }}
                      className='heading'></h5>
                    <p className='content_para'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.parra,
                        }}></span>
                      <a href={`/blog/${data?.link}`} class='readmore'>
                        Read More
                      </a>
                    </p>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className='marquee_sec2'>
        <MarqueRunning />
      </div>
    </>
  );
};

export default BlogSec;
