import React from 'react';

const BlockQuote = ({ title, desc }) => {
  return (
    <div className='blockquote-sec my-5'>
      <h3>{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: desc }}></p>
    </div>
  );
};

export default BlockQuote;
