import React from 'react';
import { GiDiamonds } from '../config/icons';
import { carouselIllu, serviceImg1, serviceImg2, serviceImg3 } from '../assets/images';
import OwlCarousel from "react-owl-carousel";


const ServiceOffered = () => {
    const serviceOfferedData = [
        {
            img: serviceImg1,
            header: `Technical Blog Posts and Articles:`,
            para: `Writing intelligent and nuanced pieces that shed light on the most recent developments in technology and offer readers in-depth assessments and professional viewpoints`,
        },
        {
            img: serviceImg2,
            header: `White Papers and Case Studies:`,
            para: `Crafting reputable white papers and captivating case studies that, by painstaking research and practical implementation, illustrate the revolutionary potential of your innovations.`,
        },
        {
            img: serviceImg3,
            header: `Technical Documentation:`,
            para: `Producing accurate and easy-to-read technical documentation that enables developers and end users to use and integrate your cutting-edge technology with ease.`,
        },
        {
            img: serviceImg1,
            header: `Research Reports:`,
            para: `Crafting thorough research reports that showcase innovative discoveries and datadriven perspectives can help you position your company as a leader in technological innovation.`,
        },
        {
            img: serviceImg2,
            header: `Website Content:`,
            para: `Creating polished, interesting material for your website that clearly communicates your technical expertise and creative solutions to draw in and win over potential clients and partners.`,
        },
        {
            img: serviceImg3,
            header: `Proposal and Grant Writing:`,
            para: `Crafting well-reasoned, elegant grant applications and proposals that clearly articulate the goals of your project and the benefits it can offer in order to secure critical funds and support.`,
        },
        {
            img: serviceImg1,
            header: `Training Materials and Educational Content:`,
            para: `Producing comprehensive and captivating training materials and educational content that support advanced learning and skill development and guaranteeing proficiency with the latest technology.`,
        },
        {
            img: serviceImg2,
            header: `SoPs and Thesis:`,
            para: `Crafting meticulously detailed Statements of Purpose (SOPs) and theses that meet the highest academic and professional standards, and specialized content services guide you from initial drafts to final edits, enhancing the impact and effectiveness of your work and positioning your brand as a leader in quality and expertise.`,
        },
    ]
    return (
        <>
            <div className="service_offered_total_sec" >
                <div className="container">
                    <div className="heading_sec" data-aos="fade-right">
                        <span><GiDiamonds /></span>
                        <h1 className='heading' >Services Offered </h1>
                        <span><GiDiamonds /></span>
                        {/* <button className="btn seeall_btn">
                            See All
                        </button> */}
                    </div>
                    <div className="serve_carousel" data-aos="fade-up">
                        <img src={carouselIllu} className='car_img' alt="carousel Img" />
                        <OwlCarousel
                            className="owl-carousel owl-theme"
                            margin={20}
                            center={true}
                            autoplay={true}
                            loop
                            dots={true}
                            nav={true}
                            responsive={{
                                0: {
                                    items: 1,
                                    dots: true,
                                    nav: true,
                                    stagePadding: 20,
                                    navText: [
                                        "<div class='nav-button owl-prev'><i class='fa-solid fa-arrow-left'></i></div>",
                                        "<div class='nav-button owl-next'><i class='fa-solid fa-arrow-right'></i></div>",
                                    ],
                                },
                                600: {
                                    items: 2,
                                    dots: true,
                                    nav: true,
                                    navText: [
                                        "<div class='nav-button owl-prev'><i class='fa-solid fa-arrow-left'></i></div>",
                                        "<div class='nav-button owl-next'><i class='fa-solid fa-arrow-right'></i></div>",
                                    ],
                                },
                                1000: {
                                    items: 3,
                                    dots: true,
                                    nav: true,
                                    navText: [
                                        "<div class='nav-button owl-prev'><i class='fa-solid fa-arrow-left'></i></div>",
                                        "<div class='nav-button owl-next'><i class='fa-solid fa-arrow-right'></i></div>",
                                    ],
                                },
                            }}
                        >
                            {
                                serviceOfferedData?.map((dat, index) => {
                                    return (
                                        <div key={index} className="item">
                                            <div className="servicesoffered_card">
                                                <img src={dat?.img} alt="service" />
                                                <h6>{dat?.header}</h6>
                                                <p>{dat?.para}</p>
                                                <button className='begun_btn btn'>
                                                    Begin Now
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceOffered