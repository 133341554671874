import React from 'react';
import { authorCircleImg } from '../../assets/images';
import { AUTHOR_NAME } from '../../config/dataConfig';

const AuthorCard = ({ content }) => {
  return (
    <div className='author-card'>
      <img src={authorCircleImg} alt='author' />
      <div className='author-titles'>
        <h3>{AUTHOR_NAME}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default AuthorCard;
