import React from 'react';

import {
  listIconImg,
  writer,
  starImg,
  marqueeIllustratorImg,
} from '../assets/images';
import MarqueRunning from './MarqueRunning';
import { AUTHOR_NAME } from '../config/dataConfig';

const MarqueeSec = () => {
  return (
    <>
      <div className='total_marquesec' id='about'>
        <MarqueRunning />
        <div className='writer_sec'>
          <img className='illu' src={marqueeIllustratorImg} alt='' />
          <div className='container'>
            <div className='row marque_row' data-aos='fade-up'>
              <div className='col-lg-4 col-sm-12 col-md-6'>
                <div className='writer_img'>
                  <img src={writer} alt='writer' />
                </div>
              </div>
              <div className='col-lg-8 col-sm-12 col-md-6' data-aos='fade-down'>
                <div className='content_sec'>
                  <div className='sec_one'>
                    <div className='img_sec'>
                      <img
                        src={listIconImg}
                        alt='list'
                        className='illustrator'
                      />
                    </div>
                    <div className='content' data-aos='fade-down'>
                      <h1>
                        Hey, I am <span className='name'>{AUTHOR_NAME}!</span>
                      </h1>
                    </div>
                  </div>
                  <div className='sec_one'>
                    <div className='img_sec'>
                      <img
                        src={listIconImg}
                        alt='list'
                        className='illustrator'
                      />
                    </div>
                    <div className='content'>
                      <p>
                          I'm an experienced content writer with a
                          speciality in technical fields like data
                          science, data analytics, machine learning,
                          AI and cybersecurity. I thrive at turning
                          complex ideas into readable, credible
                          writing. My knowledge meets the exacting
                          requirements of tech-savvy readers,
                          business experts and novices. Whether you
                          need in-depth articles, extensive studies, or
                          other complex content, you can rely on my
                          abilities and expertise to produce excellent,
                          persuasive writing on any subject you
                          require.
                      </p>
                    </div>
                  </div>
                  <div className='sec_one'>
                    <div className='img_sec'>
                      <img src={starImg} alt='list' className='star_img' />
                    </div>
                    <div className='content'>
                      <h6>
                          Let’s connect to craft content that connects and resonates
                      </h6>
                    </div>
                  </div>
                  {/* <button className='btn primary_btns'>Begin Now</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarqueeSec;
