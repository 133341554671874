import React from 'react';
import AuthorCard from './AuthorCard';

const BlogBanner = ({
  heading,
  paragraph1,
  thumbnail,
  paragraph2,
  authorCardContent,
}) => {
  return (
    <>
      <div className='blog_banner_sec'>
        <div className='container'>
          <div className='row banner_row'>
            <div className=' col-sm-12 text-start' data-aos='fade-up'>
              <h1 className='head'>{heading}</h1>
              <div className='parra_sec'>
                <h6>{paragraph1}</h6>
              </div>
              <AuthorCard content={authorCardContent} />
              <img
                src={thumbnail}
                alt='Blog detail'
                className='blog-detail-img'
              />
              <div className='parra_sec'>
                <h6>{paragraph2}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogBanner;
