import React from 'react';
import BlogBanner from '../../components/blog/BlogBanner';
import blogThumbnail from '../../assets/images/blog/blog3/thumbnail.png';
import blogInnerImage1 from '../../assets/images/blog/blog3/img-1.png';
import blogInnerImage2 from '../../assets/images/blog/blog3/img-2.png';
import BlockQuote from '../../components/blog/BlockQuote';
import ContentSectionWithImg from '../../components/blog/ContentSectionWithImg';
import ContentSection from '../../components/blog/ContentSection';
import KeyConcepts from '../../components/blog/KeyConcepts';

const Blog3 = () => {
  return (
    <div>
      <BlogBanner
        heading={`Federated Learning: A Data
        Odyssey`}
        paragraph1={`In an era where data is as valuable as currency, the concept of federated learning emerges as a
        revolutionary approach in the realm of machine learning. This technique enables a model to be
        trained across multiple decentralised devices or servers with local data samples, without the
        necessity of exchanging them.`}
        thumbnail={blogThumbnail}
        paragraph2={`Imagine a world where your personal data whispers its secrets into
        a machine's ear, yet remains shrouded in mystery. This is not just a technological leap; it's a
        paradigm shift, transforming how we think about and handle the goldmine of data in our digital
        age. It's a response to the growing concerns over privacy and security in the traditional
        centralised machine learning methods, where data centralisation often poses risks of breaches
        and misuse.`}
        authorCardContent={`07 March, 2024 | 5 min read`}
      />
      <div className='brown-bg pt-1'>
        <div className='container'>
          <ContentSection
            isDiamondPresent={false}
            heading={`Background and Evolution`}
            paragraph={`Federated learning is not a spontaneous development but an evolutionary response to the
            challenges faced in the data-centric world. Historically, as machine learning and AI grew, so did
            the concerns around data privacy and security. The evolution of federated learning can be traced
            back to the need to harness the power of data while respecting individual privacy. Technological
            developments, especially in data encryption and faster internet connectivity, have played a crucial
            role in making federated learning a viable option.`}
          />
          <ContentSection
            isDiamondPresent={false}
            heading={`Core Concepts of Federated Learning: The Secret
            Society of Data`}
          />

          <KeyConcepts
            title={`Key Concepts`}
            split3One={`<b>Local Training:</b> The crux of federated learning lies in its ability to train algorithms locally on devices. Imagine your
            device as a dojo where data ninjas train in secret. They learn the moves (data patterns), but they
            don't blab about who they're learning from. This local training ensures your data never leaves your
            device – it's the ninja code of data privacy! Here, the data never leaves its original location,
            eliminating the risks associated with data transfer. Instead, the model learns from this data and
            sends only the updates or the learning gained to a central server.`}
            split3Two={`<b>Aggregation of Learning :</b> These updates from various devices are then aggregated to update the global model or a central server. It's like a roundtable of wise elders, pooling their knowledge without spilling the beans
            about their sources. This process ensures that the model benefits from diverse data sources
            without compromising on individual data privacy.`}
            split3Three={`<b>Privacy and Security : </b>Federated learning inherently enhances data privacy and security. Since the data remains on the local device and only the learning is shared, the risks of data breaches and misuse are
            significantly minimised. This is a huge advantage and a major reason for federated learning.`}
          />

          <img
            src={blogInnerImage1}
            alt='Blog Inner Image'
            className='img img-responsive mb-5'
          />

          <KeyConcepts
            title={`Key Advantages`}
            split3One={`<b>Data Privacy : </b> The most prominent advantage of federated learning is the enhanced privacy it offers. By allowing
            data to remain on the user’s device, it ensures a high degree of confidentiality and security. It
            ensures that sensitive information remains tucked away safely, providing a haven of privacy in an
            otherwise exposed digital ecosystem.`}
            split3Two={`<b>Efficiency in Diverse Data Utilisation : </b> Federated learning efficiently utilises diverse data sources. Since it can learn from data present in
            various devices, it ensures a more comprehensive and diverse learning experience for the model.
            It's a system that thrives on variety, ensuring models are well-rounded, robust, and reflective of a
            broad spectrum of experiences and environments.`}
            split3Three={`<b>Reduced Data Transmission Costs :</b> With federated learning, the cost associated with data transmission is significantly reduced as only relevant model updates are transmitted, not the data itself. This streamlined approach not
            only speeds up the process but also cuts down on the resources required for data transfer. It's a
            more economical and efficient route, ensuring that the journey of data from local devices to the
            central model is both swift and cost-effective.`}
            split2One={`<b>Enhanced Model Performance :</b> n federated learning, each participating device contributes to a symphony of collective
            intelligence. This collaborative approach harnesses a wider range of data, leading to models that
            are not only more inclusive but also more accurate and effective. It's like an orchestra where each
            instrument adds depth and nuance to the overall performance, resulting in a richer, more
            harmonious sound.`}
            split2Two={`<b>Scalability and Flexibility : </b>Federated learning offers remarkable scalability and flexibility adapting effortlessly to varying data
            volumes and types. This adaptability makes it suitable for a range of applications, from smallscale
            projects to large, complex systems. It's a dance that changes its steps to match the music,
            ensuring that it remains relevant and effective regardless of the setting.`}
          />

          <ContentSection
            heading={`Challenges and Limitations`}
            isDiamondPresent={false}
          />

          <BlockQuote
            title={`Communication Overhead`}
            desc={`One of the significant challenges is the communication overhead involved in coordinating and
            communicating updates between different devices - much like dealing with an overzealous party
            planner – a bit chaotic but mostly fun.`}
          />

          <BlockQuote
            title={`Heterogeneity of Data`}
            desc={`The non-IID (non- Independent and Identically Distributed) nature of data across devices poses a
            challenge in ensuring the model learns effectively from such diverse and distributed data sources.
            Imagine a party where every guest speaks a different language. It’s a blast, but boy, does it take
            effort to get everyone on the same dance rhythm!`}
          />
          <BlockQuote
            title={`Security Risks`}
            desc={`Despite its advantages in privacy, federated learning is not devoid of security risks. Issues like
            model poisoning, where bad actors manipulate the learning process, remain a concern.`}
          />
          <div className='row'>
            <div className='col-sm-12 col-md-8 col-lg-8'>
              <ContentSection
                heading={`Applications of Federated Learning`}
                isDiamondPresent={false}
              />

              <BlockQuote
                title={`Healthcare`}
                desc={`In healthcare, federated learning enables the development of predictive models by learning from
                patient data stored across various hospitals, without compromising patient privacy.`}
              />

              <BlockQuote
                title={`Finance`}
                desc={`In the financial sector, it aids in fraud detection and risk assessment by learning from financial
                transaction data spread across different institutions.`}
              />

              <BlockQuote
                title={`Smart Devices`}
                desc={`For smart devices, federated learning improves functionality and user experience by learning from
                user interactions while keeping their data private.`}
              />
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
              <img
                src={blogInnerImage2}
                alt=''
                className='img img-responsive'
              />
            </div>
          </div>

          <ContentSection
            heading={`Future Directions and Potential`}
            isDiamondPresent={false}
          />
          <BlockQuote
            title={`Technological Advancements`}
            desc={`The future of federated learning is tied to advancements in technology, particularly in areas of
            secure data aggregation and efficient model training.`}
          />
          <BlockQuote
            title={`Expansion into New Areas`}
            desc={`This approach has the potential to expand into various new domains, revolutionising how datadriven
            decisions are made while respecting privacy.`}
          />
          <BlockQuote
            title={`Ethical and Regulatory Considerations`}
            desc={`As federated learning evolves, it will be crucial to navigate the ethical and regulatory landscape to
            ensure its benefits are maximised without infringing on individual rights.`}
          />

          <ContentSection
            extraClassName={'pb-5'}
            heading={`Conclusion`}
            paragraph={`Federated learning stands as a beacon of hope in balancing the power of data-driven insights
            with the sanctity of individual privacy. Its importance is set to grow in a world increasingly driven
            by data, where privacy concerns cannot be overlooked.
            As we embrace this new paradigm, it’s essential to continue exploring its potential while being
            mindful of the challenges and responsibilities it brings. Federated learning is not just a
            technological advancement; it's a step towards a more ethical and secure approach to harnessing
            the power of data.`}
            list={true}
            listHeading={`More References on Federated Learning :`}
            listItems={[
              `McMahan, H. Brendan, et al. "Communication-efficient learning of deep networks from
              decentralized data. https://arxiv.org/abs/1602.05629`,
              `Konečný, Jakub, et al. "Federated optimization: Distributed machine learning for on-device
              intelligence. https://arxiv.org/abs/1610.02527`,
              `T. Li, A. K. Sahu, A. Talwalkar and V. Smith, "Federated Learning: Challenges, Methods, and
              Future Directions," in IEEE Signal Processing Magazine, vol. 37, no. 3, pp. 50-60, May 2020, doi:
              10.1109/MSP.2020.2975749.`,
            ]}
          />

          {/* 
          <ContentSectionWithImg
            heading={`The Tale of Targeted Marketing`}
            paragraph={`Ever used a map app to get the fastest route? That’s Data
          Analytics checking traffic patterns and AI adjusting your route in
          real-time. If you ever avoided a two-hour traffic jam, thank your
          lucky stars for this tech tag-team. Traffic congestion is a daily
          challenge, especially in the metropolitan areas. By leveraging AI,
          traffic signals adapt in real-time to traffic conditions, gathered
          through a network of sensors and cameras. This information is
          crunched using advanced data analytics to predict flow patterns
          and adjust signals accordingly to minimise congestion.
          <br />
          <br />
          E-commerce platforms employ AI-driven recommendation engines to
          analyse the vast amounts of data they gather about consumer
          behaviour. Through machine learning algorithms, they can predict
          what products a customer is likely to be interested in, leading to
          a highly personalised shopping experience.`}
            img={blogDetail1inner1}
          />
          <ContentSectionWithImg
            heading={`The Fitness Coaches You Never Knew You Needed`}
            paragraph={`Healthcare has also seen a tremendousimpact with the introduction of AI anddata analytics in everyday devices suchas smartwatches and fitness trackers.They're mini-labs on your wrist, poweredby—you guessed it—Data Analytics andAI. They monitor your vitals like heartrate, sleep patterns, physical activity, andcheer you on to take those 10,000 steps,and even nudge you to get more sleep.
            <br />
            <br />
          These devices collect data on AI algorithms that can detect anomalies that may indicate health issues, often before Captionthe user notices any symptoms, and suggest early interventions.`}
            img={blogDetail1inner2}
            isContentRight={true}
          />

          <BlockQuote
            title={`The Secret Recipe Behind Your Streaming Binges`}
            desc={`Ever wondered how your streaming service knows you'd love that quirky rom-com or that spine-chilling thriller? That’s our power couple at play again. They analyse your viewing habits and suggest shows that keep you glued to your couch, popcorn in hand.`}
          />
          <BlockQuote
            title={`Smarter Homes for Smarter Living`}
            desc={`Smart homes used to be the stuff of sci-fi, but now they're just... homes. Thermostats learn your comfort levels, lights know when you're home, and fridges can even remind you to buy milk. It's like having a butler who's also a genius.`}
          />
          <BlockQuote
            title={`Synergy in Practice`}
            desc={`The synergy between data analytics and artificial intelligence is more than just their combined use; it is about the harmonious interaction that amplifies their individual capabilities. Data Analytics provides the groundwork by gathering and making sense of data and Artificial Intelligence uses this data to learn, predict, and act without human intervention.`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Blog3;
