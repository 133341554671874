import React from 'react';
import { GiDiamonds } from 'react-icons/gi';

const ContentSection = ({
  extraClassName = '',
  isDiamondPresent = true,
  heading = null,
  paragraph = null,
  list = false,
  listHeading = null,
  listItems,
  belowListPara = null,
}) => {
  return (
    <div className={`content-section ${extraClassName}`}>
      <div className='row'>
        <div className='content'>
          {heading && (
            <h3>
              {isDiamondPresent && <GiDiamonds />} {heading}
            </h3>
          )}
          {paragraph && <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>}
        </div>
        <div className='list'>
          {list && (
            <div>
              {listHeading && <p className='heading'>{listHeading}</p>}
              <ul>
                {listItems.map((listItem) => (
                  <li dangerouslySetInnerHTML={{ __html: listItem }}></li>
                ))}
              </ul>
            </div>
          )}
          {belowListPara && (
            <p dangerouslySetInnerHTML={{ __html: belowListPara }}></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
