import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import BlogSec from '../components/BlogSec';
import Footer from '../components/Footer';
import Blog1 from './blogs/Blog1';
import Blog2 from './blogs/Blog2';
import Blog3 from './blogs/Blog3';
import Blog4 from './blogs/Blog4';
import Blog5 from './blogs/Blog5';
import Blog6 from './blogs/Blog6';
import Blog7 from './blogs/Blog7';
import Blog8 from './blogs/Blog8';
import Blog9 from './blogs/Blog9';
import Blog10 from './blogs/Blog10';
import Blog11 from './blogs/Blog11';
import { Route, Routes, useParams } from 'react-router-dom';

export const blogLinks = [
  {
    path: `the-symbiotic-relationship-of-the-21st-century`,
    element: <Blog1 />,
  },
  {
    path: `journey-into-the-heart-of-explainable-ai`,
    element: <Blog2 />,
  },
  {
    path: `data-odyssey`,
    element: <Blog3 />,
  },
  {
    path: `matrix-world-neural-networks`,
    element: <Blog4 />,
  },
  {
    path: `generative-adversarial-networks`,
    element: <Blog5 />,
  },
  {
    path: `quantum-computing-met-ai`,
    element: <Blog6 />,
  },
  {
    path: `digital-twin-looking-mirror`,
    element: <Blog7 />,
  },
  {
    path: `neuro-symbolic-ai`,
    element: <Blog8 />,
  },
  {
    path: `market-mix-modelling`,
    element: <Blog9 />,
  },
  {
    path: `linguistic-alchemy-natural-language-processing`,
    element: <Blog10 />,
  },
  {
    path: `collaborative-ai`,
    element: <Blog11 />,
  },
];

const Blog = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  let { blogpath } = useParams();
  const BlogSecT = blogLinks.find((blogLink) => blogLink.path === blogpath);
  const BlogSectionElement = BlogSecT.element;
  return (
    <>
      <Navbar />
      {BlogSectionElement}
      <BlogSec bg={`bg-white`} title={`Other Blogs`} showSeeAll={false} />
      <Footer />
    </>
  );
};

export default Blog;
