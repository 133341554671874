import React from 'react';

const ContentSectionWithImg = ({
  heading,
  paragraph,
  img,
  isContentRight = false,
}) => {
  return (
    <div className='content-section-with-img'>
      <div className='row'>
        <div
          className={`col-sm-12 col-md-6 col-lg-6 ${
            isContentRight && 'order-1'
          }`}>
          <div className='content'>
            <h3>{heading}</h3>
            <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <div className='image'>
            <img src={img} alt='Blog Detail Image ' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSectionWithImg;
