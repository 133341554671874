import React from 'react';

const KeyConcepts = ({
  title = null,
  split2One,
  split2Two,
  split3One,
  split3Two,
  split3Three,
  pextraClassName = '',
}) => {
  return (
    <div className='key-concepts'>
      {title && (
        <div className='text-center'>
          <button className='see_allbtn btn'>{title}</button>
        </div>
      )}

      <div className='content-sec split-3'>
        <p
          className={pextraClassName}
          dangerouslySetInnerHTML={{ __html: split3One }}></p>
        <p
          className={pextraClassName}
          dangerouslySetInnerHTML={{ __html: split3Two }}></p>
        <p
          className={pextraClassName}
          dangerouslySetInnerHTML={{ __html: split3Three }}></p>
      </div>
      <div className='content-sec split-2'>
        <p
          className={pextraClassName}
          dangerouslySetInnerHTML={{ __html: split2One }}></p>
        <p
          className={pextraClassName}
          dangerouslySetInnerHTML={{ __html: split2Two }}></p>
      </div>
    </div>
  );
};

export default KeyConcepts;
