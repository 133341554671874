import React from 'react'
import { GiDiamonds } from '../config/icons'
import { illuImg1, illuImg2, line, work1Img, work2Img, work3Img, work4Img } from '../assets/images';

const WhyWork = () => {
    const workData = [
        {
            img: work1Img,
            head: `Deep Domain Expertise`,
            parra: `I deliver exceptional richness in my writing, which is supported by my extensive experience and continuous learning in multiple fields. This guarantees that every piece I craft is informed, insightful, and adds real value to your audience.`
        },
        {
            img: work2Img,
            head: `Customized Content`,
            parra: `By recognizing the uniqueness of your brand and its audience, I customize my writing to align with your specific needs and objectives, ensuring that the content we create together resonates deeply and effectively.`
        },
        {
            img: work3Img,
            head: `Quality and Precision`,
            parra: `My unwavering dedication to quality ensures that each piece of content is meticulously researched, elegantly crafted, and impeccably accurate. Precision in language and detail ensures that intricate technical concepts are articulated with clarity and are impactful.`
        },
        {
            img: work4Img,
            head: `Strategic Content Creation:`,
            parra: `Strategic writing revolutionizes content marketing by seamlessly aligning each piece with your brand's objectives and audience needs. By harnessing data-driven insights, I craft compelling narratives that not only captivate but also convert. This meticulous approach ensures coherence across all platforms, amplifying your brand's reach and impact with unparalleled precision.`
        },
    ]
    return (
        <>
            <div className="workwithus_total_sec">
                <img src={illuImg1} className='illuimg1' alt="" />
                <img src={illuImg2} className='illuimg2' alt="" />
                <div className="container">
                    <h1 data-aos="fade-up" className='heading' ><span><GiDiamonds /></span>
                        <span><GiDiamonds /></span></h1>
                    <div className="workrow row" data-aos="fade-down">
                        {
                            workData?.map((dat, index) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="toalcard">
                                            <div key={index} className="work_card">
                                                <img src={dat?.img} alt="work1" className="top_img" />
                                                <h6>{dat?.head}</h6>
                                                <p>{dat?.parra}</p>
                                            </div>
                                            {
                                                index === 3 ? null : <div className="line">
                                                    <img src={line} alt="line" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyWork