import React from 'react'
import Marquee from "react-fast-marquee";
import { GiDiamonds } from '../config/icons';
const MarqueRunningTwo = () => {
    return (
        <>
            <div className="marquee_sec">
                <Marquee autoFill={true} direction="right" speed={100}>
                    <div className="first_head">
                        <h1 className="headone">Clarity in Complexity</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Beyond Surface Writing</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Words With Impact</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Tech Stories, Expertly Told</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Redefine your Stories</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Precision in Every Word</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Power your Perspectives</h1>
                        <span><GiDiamonds /></span>
                        <h1 className='headtwo'>Content that Clicks</h1>
                        <span><GiDiamonds /></span>
                    </div>
                </Marquee>
            </div>
        </>
    )
}

export default MarqueRunningTwo