import React from 'react'

const PreLoader = () => {
    return (
        <>
            <h1>Laoding....</h1>
        </>
    )
}

export default PreLoader