import React from 'react'
import { unleashImg } from '../assets/images'
import OwlCarousel from "react-owl-carousel";
import { GiDiamonds } from '../config/icons'
import blogThumbnail1 from '../assets/images/blog/blog1/thumbnail.png';
import blogThumbnail2 from '../assets/images/blog/blog2/thumbnail.png';
import blogThumbnail3 from '../assets/images/blog/blog3/thumbnail.png';
import blogThumbnail4 from '../assets/images/blog/blog4/thumbnail.png';
import blogThumbnail5 from '../assets/images/blog/blog5/thumbnail.png';
import blogThumbnail6 from '../assets/images/blog/blog6/thumbnail.png';
import blogThumbnail7 from '../assets/images/blog/blog7/thumbnail.png';
import blogThumbnail8 from '../assets/images/blog/blog8/thumbnail.png';
import blogThumbnail9 from '../assets/images/blog/blog9/thumbnail.png';
import blogThumbnail10 from '../assets/images/blog/blog10/thumbnail.png';
import blogThumbnail11 from '../assets/images/blog/blog11/thumbnail.png';
import { blogLinks } from '../screens/Blog';

const Unleashing = () => {
    // const courouselData = [
    //     {
    //         header: `Unleashing Innovation <br/> Through Exceptional Content`,
    //         seo_content: `SEO Mastery`,
    //         writing: `Content Writing`,
    //         web: `Web Content`,
    //         parra_content: `As adept storytellers, our team crafts engaging narratives that transcend the ordinary, leaving an indelible mark on your audience. Seamlessly integrating cutting-edge SEO techniques, we propel your message to the forefront of search engine rankings, ensuring unparalleled visibility. In the realm of technical writing, our precision and clarity demystify complexity, establishing your authority in the industry. Blogs become vibrant expressions of your brand's heartbeat, as we navigate the blogosphere with expertise, capturing attention and fostering meaningful online engagement.`
    //     },
    //     {
    //         header: `Unleashing Innovation<br/> Through Exceptional Content`,
    //         seo_content: `SEO Mastery`,
    //         writing: `Content Writing`,
    //         web: `Web Content`,
    //         parra_content: `As adept storytellers, our team crafts engaging narratives that transcend the ordinary, leaving an indelible mark on your audience. Seamlessly integrating cutting-edge SEO techniques, we propel your message to the forefront of search engine rankings, ensuring unparalleled visibility. In the realm of technical writing, our precision and clarity demystify complexity, establishing your authority in the industry. Blogs become vibrant expressions of your brand's heartbeat, as we navigate the blogosphere with expertise, capturing attention and fostering meaningful online engagement.`
    //     },
    //     {
    //         header: `Unleashing Innovation<br/> Through Exceptional Content`,
    //         seo_content: `SEO Mastery`,
    //         writing: `Content Writing`,
    //         web: `Web Content`,
    //         parra_content: `As adept storytellers, our team crafts engaging narratives that transcend the ordinary, leaving an indelible mark on your audience. Seamlessly integrating cutting-edge SEO techniques, we propel your message to the forefront of search engine rankings, ensuring unparalleled visibility. In the realm of technical writing, our precision and clarity demystify complexity, establishing your authority in the industry. Blogs become vibrant expressions of your brand's heartbeat, as we navigate the blogosphere with expertise, capturing attention and fostering meaningful online engagement.`
    //     },
    //     {
    //         header: `Unleashing Innovation<br/> Through Exceptional Content`,
    //         seo_content: `SEO Mastery`,
    //         writing: `Content Writing`,
    //         web: `Web Content`,
    //         parra_content: `As adept storytellers, our team crafts engaging narratives that transcend the ordinary, leaving an indelible mark on your audience. Seamlessly integrating cutting-edge SEO techniques, we propel your message to the forefront of search engine rankings, ensuring unparalleled visibility. In the realm of technical writing, our precision and clarity demystify complexity, establishing your authority in the industry. Blogs become vibrant expressions of your brand's heartbeat, as we navigate the blogosphere with expertise, capturing attention and fostering meaningful online engagement.`
    //     },
    // ]

    const blogDatas = [
        {
            title: `The Symbiotic Relationship of the 21st Century`,
            paragraph: `Once upon a time, in the not-so-distant past, Data Analytics
            and Artificial Intelligence (AI) were just buzzwords that
            seemed exclusive to tech moguls and spy movies. Fast forward
            to today, and this duo has infiltrated our daily lives so
            smoothly that we barely notice their presence—from suggesting
            the quickest route to work to filtering out spam emails that
            no human eye should ever witness.`,
            thumbnail: blogThumbnail1,
            path: blogLinks[0].path,
        },
        {
            title: `Unboxing the AI Black Box: A Journey into the
            Heart of Explainable AI`,
            paragraph: `In a world dazzled by the wizardry of Artificial Intelligence (AI), Explainable AI or XAI is the affable professor who takes you aside and whispers, “Let me show you how the magic trick works.” It's the antidote to the often-perplexing AI show where, without XAI, the audience is left applauding a performance they scarcely understand.`,
            thumbnail: blogThumbnail2,
            path: blogLinks[1].path,
        },
        {
            title: `Federated Learning: A Data
            Odyssey`,
            paragraph: `In an era where data is as valuable as currency, the concept of federated learning emerges as a
            revolutionary approach in the realm of machine learning. This technique enables a model to be
            trained across multiple decentralised devices or servers with local data samples, without the
            necessity of exchanging them.`,
            thumbnail: blogThumbnail3,
            path: blogLinks[2].path,
        },
        {
            title: `The Matrix World of Neural Networks`,
            paragraph: `Neural networks, often referred to as artificial neural networks (ANNs), have revolutionised the
            field of machine learning and artificial intelligence (AI) in recent years. These versatile
            computational models have found applications in various domains, from image and speech
            recognition to natural language processing and autonomous vehicles.`,
            thumbnail: blogThumbnail4,
            path: blogLinks[3].path,
        },
        {
            title: `Understanding GANs - Generative Adversarial Networks`,
            paragraph: `Generative Adversarial Networks, or GANs, represent a revolutionary breakthrough in the field of
            artificial intelligence and machine learning. GANs have become one of the most prominent and
            influential concepts in the realm of deep learning. This article rovides a comprehensive overview
            of GANs, exploring their structure, training process, applications, and recent advancements.`,
            thumbnail: blogThumbnail5,
            path: blogLinks[4].path,
        },
        {
            title: `When Quantum Computing met AI`,
            paragraph: `In the fascinating universe of technology, two stars have been gaining luminosity: Quantum
            Computing and Artificial Intelligence (AI). They're like the dynamic duo of tech, where Quantum
            Computing dons the cape of super-speed processing, and AI wears the hat of Mr. Know-It-All. But
            what truly jazzes up their performance? It's the unsung hero, Data Analytics. Let’s get to know
            how the true masters of the tech world work together.`,
            thumbnail: blogThumbnail6,
            path: blogLinks[5].path,
        },
        {
            title: `Digital Twin: It's Like Looking In A Mirror`,
            paragraph: `As the name suggests, a digital twin is a digital representation of a physical object, system, or
            process. This concept integrates various technologies, including the Internet of Things (IoT),
            artificial intelligence (AI), machine learning, and data analytics, to create a dynamic, real-time
            simulation of a physical entity.`,
            thumbnail: blogThumbnail7,
            path: blogLinks[6].path,
        },
        {
            title: `Neuro-Symbolic AI: The Convergence of Neural Networks and Symbolic Reasoning`,
            paragraph: `The field of artificial intelligence (AI) has witnessed a significant evolution, driven by the
            emergence of neuro-symbolic AI, a paradigm that merges the strengths of neural networks (deep
            learning) with symbolic artificial intelligence. This integration aims to address the limitations of
            both approaches, offering more robust, interpretable, and efficient AI systems.`,
            thumbnail: blogThumbnail8,
            path: blogLinks[7].path,
        },
        {
            title: `Market Mix Modelling: The Subtle Art of Decoding Marketing
            Dynamics`,
            paragraph: `Market Mix Modelling, often abbreviated as MMM, is an analytical technique used in marketing to understand how various marketing activities contribute to sales and other key performance
            indicators (KPIs). It's essentially a way of deciphering the effectiveness of different marketing
            elements and how they drive consumer behaviour and business performance.`,
            thumbnail: blogThumbnail9,
            path: blogLinks[8].path,
        },
        {
            title: `The Linguistic Alchemy of Natural Language Processing`,
            paragraph: `In a world where machines converse, jokes are decoded by algorithms, and books are summarised
            in the blink of an eye, lies the enchanting realm of Natural Language Processing (NLP).`,
            thumbnail: blogThumbnail10,
            path: blogLinks[9].path,
        },
        {
            title: `Collaborative AI: Harnessing the
            Power of Collective Intelligence`,
            paragraph: `The advent of AI has traditionally been viewed through a lens of automation and the replacement
            of human tasks. What was once considered to be achieved by purely “human intelligence” can
            now be fed into a machine that understands simply 1s and 0s to give out solutions that awe the
            very human intelligence itself.`,
            thumbnail: blogThumbnail11,
            path: blogLinks[10].path,
        },
    ]

    return (
        <>
            <div className="unleashing_total_sec">
                <div className="container">
                    <OwlCarousel
                        className="owl-theme service_carousel"
                        autoplay={true}
                        loop={true}
                        margin={10}
                        dots={false}
                        responsive={{
                            0: {
                                items: 1,
                                animateOut: "slideOutDown",
                                animateIn: "flipInX",
                                nav: false,
                                mouseDrag: true,
                                mergeFit: true,
                                autoplayHoverPause: true,
                            },
                            600: {
                                items: 1,
                                animateOut: "slideOutDown",
                                animateIn: "flipInX",
                                nav: false,
                                mouseDrag: true,
                                mergeFit: true,
                                autoplayHoverPause: true,
                            },
                            1000: {
                                items: 1,
                                animateOut: "slideOutDown",
                                animateIn: "flipInX",
                                nav: true,
                                mouseDrag: true,
                                mergeFit: true,
                                autoplayHoverPause: true,
                                navText: [
                                    "<div class='nav-button owl-prev'><i class='fa-solid fa-arrow-left'></i></div>",
                                    "<div class='nav-button owl-next'><i class='fa-solid fa-arrow-right'></i></div>",
                                ],
                            },
                        }}
                    >
                        {
                            blogDatas.map((dat, index) => {
                                return (
                                    <div className="row unleashrow">
                                        <div key={index} className="col-lg-5 col-md-6 col-sm-12">
                                            <div className="img_card">
                                                <img src={dat?.thumbnail} alt="unleash" />
                                                <a type='button' href={`/blog/${dat?.path}`} id='services' className="btn readmore_btn">
                                                    Read More...
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 col-sm-12" data-aos="fade-down">
                                            <div className="serivices_card">
                                                <div className="total_content">
                                                    <h1
                                                        className="service_heading"
                                                    >{dat?.title}</h1>
                                                    <div className="features_sec">
                                                        <h6>SEO Mastery</h6> <span><GiDiamonds /></span>  <h6>
                                                            Content Writing
                                                        </h6>
                                                        <span><GiDiamonds /></span>
                                                        <h6>Web Content</h6>
                                                    </div>
                                                    <div className="parra_content">
                                                        <p>
                                                            {dat?.paragraph}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </div>
        </>
    )
}

export default Unleashing